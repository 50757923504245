<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-3 m-3 c-12">
                    <comment :objectType="46" :objectId="this.$route.params.id" />
                </div>
                <div class="col l-9 m-9 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Dự toán làm hàng STST</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                            
                            </div>
                        </div>
                        <div class="form-body">
                            <div class="wrapper">
                                <div class="row no-gutters">
                                    <div class="col l-4 m-4 c-12">
                                        <div class="left">
                                            <img src="../../../assets/images/logo.png" />
                                        </div>
                                    </div>
                                    <div class="col l-8 m-8 c-12">
                                        <div class="center">
                                            <div class="title"><h2>DỰ TOÁN LÀM HÀNG STST</h2></div>
                                            <div class="code"><span class="label">Số: </span><span>{{entity.estimateCode}}</span></div>
                                            <div class="date"><span class="label">Ngày: </span><span>{{entity.estimateDate}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters" style="padding-top:10px;">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="line"> 
                                            <label>Mã phiếu: </label>
                                            <div>{{entity.estimateCode}}</div>
                                        </div>
                                        <div class="line"> 
                                            <label>Người lập phiếu: </label>
                                            <div>{{entity.staff.fullName}}</div>
                                        </div>
                                        <div class="line-2">
                                            <div class="line"> 
                                                <label>Ghi chú: </label>
                                                <div>{{entity.note}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col l-12 m-12 c-12" style="padding-top:20px;">
                                        <table class="table-1">
                                            <thead>
                                                <tr>
                                                    <th style="width:80px;">STT</th>
                                                    <th style="width:400px; text-align: left; padding-left: 10px;">Chi phí</th>
                                                    <th style="width:120px; text-align: left; padding-left: 10px;">Loại</th>
                                                    <th style="width:150px; text-align: right; padding-right: 10px;">Đơn giá</th>
                                                    <th style="width:100px;">Vat</th>
                                                    <th style="width:150px; text-align: right; padding-right: 10px;">Thành tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in entity.details" :key="'detail' + index">
                                                    <td class="stt">{{index + 1}}</td>
                                                    <td>{{item.fee.feeName}}</td>
                                                    <td style="text-align: left;">{{ getFeeType(item.type) }}</td>
                                                    <td style="text-align: right;">{{formatNumber(item.estimatePrice)}}</td>
                                                    <td style="text-align: center;">{{formatNumber(item.estimateVat)}}%</td>
                                                    <td style="text-align: right;">{{formatNumber(item.estimateAmount)}}</td>
                                                </tr>
                                            </tbody>
                                            
                                            <tbody>
                                                <tr>
                                                    <td colspan="5" class="stt"><b>Tổng cộng</b></td>
                                                    <td style="text-align: right; font-weight: bold;">{{ formatNumber(entity.estimateAmount) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import orderHeavyEstimateService from '@/api/orderHeavyEstimateService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';
    import comment from '../../../components/Comment.vue';

    export default ({
        components: {
            comment
        },
        metaInfo: {
            title: 'Dự toán làm hàng STST'
        },
        data() {
            return {
               title: 'Dự toán làm hàng STST',
               id: 0,
               submitted: false,
               loadding: false,
               details: [],
               entity: { id: 0, staff: { fullName: '' }, customer: { companyName: '' } },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getById(){
                this.setLoading(true);
                orderHeavyEstimateService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
    })

</script>

<style scoped>
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 16px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 18px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 18px;
    }
    .line span{
        font-size: 18px;
        font-weight: bold;
    }
    .line div {
        font-size: 18px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 14px;
    }
    .table-1 {
        min-height: 200px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 14px;
    }
</style>
